/* ------------------------------ GENERAL SETTINGS ------------------------------ */
.App {
  min-height: 85vh;
  padding: 1rem;
  margin: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
}

.power_container {
  display: flex;
  justify-content: flex-end;
}

.name_display_container {
  display: flex;
  justify-content: center;
  min-height: 3rem;
}

.name_display_container span {
  font-size: 2rem;
}
/* ------------------------------ END GENERAL SETTINGS ------------------------------ */


/* ------------------------------ SWITCH BUTTON ------------------------------ */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* ------------------------------ END SWITCH BUTTON ------------------------------ */


/* ------------------------------  DRUM BUTTONS ------------------------------ */
.drums_container {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 5rem 1rem;
  grid-template-areas: 
    ". ."
    ". ."; 
  justify-content: space-around; 
  align-content: center; 
  justify-items: center; 
  align-items: center; 
}

.drums_container__button {
  cursor: pointer;
  min-width: 8rem;
  min-height: 8rem;
  background: lightblue;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: navajowhite;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
}

.drums_container__button:disabled {
  cursor: not-allowed;
}

.drums_container__button--letter {
  font-size: initial;
}
/* ------------------------------ END - DRUM BUTTONS ------------------------------ */


/* ------------------------------ VOLUME SLIDER ------------------------------ */
.volume__container {
  position: relative;
}

.volume_container__display {
  position: absolute;
  top: -2rem;
  font-size: 1.5rem;
  right: 0;
}

.volume_range {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.volume_range:hover {
  opacity: 1;
}

.volume_range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.volume_range::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.volume_range:disabled {
  cursor: not-allowed;
}

.volume_range:disabled:hover {
  background-color: lightgrey;
}
/* ------------------------------ END VOLUME SLIDER ------------------------------ */


/* ------------------------------ BANK SWITCH ------------------------------ */
.bank_container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
}

.bank_container .bank {
  border: 1px solid #2196f3;
  flex-basis: 25%;
  text-align: center;
  padding: 1rem;
}

.bank_container .bank.disabled {
  background: #ccc;
  cursor: not-allowed;
  border: none;
}

.bank_container .bank.selected {
  background: #2196f3;
  cursor: not-allowed;
  border: none;
}
/* ------------------------------ END BANK SWITCH ------------------------------ */
